<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <div class="row">
        <div class="col-md-4">
          <div style="height: 350px; width: 350px">
            <img
              style="width: 100%; height: 100%; object-fit: contain"
              :src="[uploadedImageUrl == '' ? form.photo : uploadedImageUrl]"
           
            />
          </div>
        </div>
        <div class="col">
          <!-- Name input -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Persediaan Bahan"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Image -->
          <b-form-group id="input-group-image">
            <label for="input-image"
              >Gambar: <em class="text-muted">opsional</em></label
            >
            <b-form-file
              v-model="form.photo"
              placeholder="Upload Gambar"
              drop-placeholder="Drop file here..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.photo }}</small>
          </b-form-group>

          <!-- Input Category -->
          <b-form-group
            id="input-group-material-category"
            label="Kategori Persediaan Bahan:"
            label-for="input-material-category"
          >
            <treeselect
              v-model="form.material_category_id"
              :multiple="false"
              :options="material_categories"
              placeholder="Pilih Kategori"
            />
            <small class="text-danger">{{ error.material_category_id }}</small>
          </b-form-group>

          <!-- Input Category -->
          <b-form-group
            id="input-group-uom"
            label="Satuan:"
            label-for="input-uom"
          >
            <treeselect
              v-model="form.uom_id"
              :multiple="false"
              :options="uoms"
              placeholder="Pilih Satuan"
            />
            <small class="text-danger">{{ error.uom_id }}</small>
          </b-form-group>

          <!-- Description input -->
          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan Persediaan Bahan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
      </div>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/materials/list')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        description: "",
        material_category_id: "",
        uom_id: "",
      },
      material_categories: [],
      uoms: [],
      imgLoaded: false,
      uploadedImageUrl: "",
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      console.log(evt);
      this.form.photo = evt.target.files[0];
    },

    async getMaterialCategoryOption() {
      let response = await module.setTreeSelect("material-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.material_categories = response.data;
        this.material_categories.unshift({
          label: "Pilih Kategori Persediaan Bahan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getUomOption() {
      let response = await module.setTreeSelect("uoms");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.uoms = response.data;
        this.uoms.unshift({
          label: "Pilih Satuan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/materials/list");
      }
    },
  },
  mounted() {
    this.getMaterialCategoryOption();
    this.getUomOption();
  },
};
</script>

<style>
</style>